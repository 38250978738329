import { apiRequest } from '.';

export type Profile = {
    sub: string;
    picture: string;
};

export function getProfile(): Promise<Profile>
{
    return apiRequest('/auth/twitch/profile', 'GET');
}
