import { getAnalytics } from 'utils/api/analytics';
import { getGroups } from 'utils/api/groups';
import { privateLoader } from 'utils/loader';

import Dashboard from './Dashboard';

async function dashboardLoader(): Promise<any | null>
{
    try
    {
        const analytics = await getAnalytics();
        const activeGroups = await getGroups({ limit: 1, page: 0, active: true, populate: true });
        const activeGroup = activeGroups[0];

        return { analytics, activeGroup };
    }
    catch (err: any)
    {
        if (err.status)
        {
            return err;
        }

        throw err;
    }
}

export default {
    path: '',
    element: <Dashboard />,
    loader: privateLoader(dashboardLoader),
    errorElement: <div>Dashboard failed to load</div>,
};
