import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';

import Fallback from 'components/Fallback';

import './index.css';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/reset.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StrictMode>
        <ConfigProvider theme={theme}>
            <Suspense fallback={<Fallback />}>
                <App />
            </Suspense>
        </ConfigProvider>
    </StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
