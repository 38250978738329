import { redirect } from 'react-router-dom';

export const baseUrl = process.env.REACT_APP_BACKEND_URL;

export type Pagination = {
    limit?: number;
    page?: number;
};

export type Query = {
    query?: string;
};

function getHeaders()
{
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    return headers;
}

export async function apiRequest<T>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', options: any = {}): Promise<T>
{
    let body = options.body;
    let params = options.params;

    const requestInit: RequestInit = {
        method,
        headers: getHeaders(),
        body: body ? JSON.stringify(body) : undefined,
        credentials: 'include',
    };

    const url = new URL(`${baseUrl}${path}`);

    if (params)
    {
        Object.keys(params).forEach(key =>
        {
            const data = params[key];

            if (Array.isArray(data))
            {
                data.forEach(value => url.searchParams.append(`${key}[]`, value));
            }
            else
            {
                url.searchParams.append(key, data);
            }
        });
    }

    const res = await fetch(url, requestInit);
    const data = await res.text();
    const parsedData = JSON.parse(data || 'null');

    if (res.status === 401)
    {
        throw redirect('/logout?error=Session expired. Please login again.');
    }

    if (!res.ok)
    {
        throw new Error(parsedData.message);
    }

    return parsedData;
}
