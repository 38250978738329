import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Button, Typography } from 'antd';
import { TbPlus, TbSearch } from 'react-icons/tb';

import Dialog from 'components/Dialog';

import styles from './index.module.scss';
import Loader from 'components/Loader';

export type AutoCompleteDialogItem = {
    key: string;
    text: string;
    subText: string;
    metadata?: JSX.Element;
};

type Props = {
    open: boolean;
    title: string;
    items: AutoCompleteDialogItem[];
    loading?: boolean;
    onClose: () => void;
    onClick: (key: string) => void;
    onCreate: (text: string) => void;
    onChange: (text: string) => void;
};

function AutoCompleteDialog({ open, title, items, loading, onClick, onCreate, onClose, onChange }: Props)
{
    const timeoutRef = useRef<NodeJS.Timeout>();

    const [text, setText] = useState<string>('');
    const [deferredText, setDeferredText] = useState<string>('');

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const disabled = loading || text !== deferredText;

    function handleChange(event: ChangeEvent<HTMLInputElement>)
    {
        const value = event.target.value;
        setText(value);

        if (timeoutRef.current)
        {
            clearTimeout(timeoutRef.current);
        }

        if (!value)
        {
            setDeferredText(value);
            onChange(value);
            return;
        }

        timeoutRef.current = setTimeout(() =>
        {
            setDeferredText(value);
            onChange(value);
        }, 500);
    }

    function handleClick(key: string)
    {
        return () => onClick(key);
    }

    function handleClose()
    {
        setText('');
        setDeferredText('');
        onClose();
    }

    function handleCreate()
    {
        onCreate(text);
        setText('');
        setDeferredText('');
    }

    function makeBold(str: string)
    {
        if (disabled)
        {
            return str;
        }

        const index = str.toLowerCase().indexOf(text.toLowerCase());

        if (index === -1)
        {
            return str;
        };

        return (
            <>
                {str.slice(0, index)}
                <b>{str.slice(index, index + text.length)}</b>
                {str.slice(index + text.length)}
            </>
        );
    }

    return (
        <Dialog
            className={styles.content}
            open={open}
            title={title}
            onClose={handleClose}
        >
            <div>
                {
                    disabled ? <Loader /> : <TbSearch />
                }

                <input
                    autoFocus
                    type="text"
                    placeholder='Start typing...'
                    value={text}
                    onChange={handleChange}
                />
            </div>

            <ul>
                {
                    items.map((item) => (
                        <li key={item.key}>
                            <Button
                                type="text"
                                disabled={disabled}
                                onClick={handleClick(item.key)}
                            >
                                <div>
                                    <Typography.Text disabled={disabled}>{makeBold(item.text)}</Typography.Text>
                                    <Typography.Text type="secondary" disabled={disabled}>{item.subText}</Typography.Text>
                                </div>
                                <div>
                                    <Typography.Text disabled={disabled}>
                                        {item.metadata}
                                    </Typography.Text>
                                </div>
                            </Button>
                        </li>
                    ))
                }
            </ul>

            <Button
                type="text"
                disabled={disabled}
                onClick={handleCreate}
            >
                <TbPlus />
                <Typography.Text disabled={disabled}>
                    Create {`${text ? `"${text}"` : "new"} ${title.slice(0, -1).toLowerCase()}`}
                </Typography.Text>
            </Button>
        </Dialog>
    );
}

export default AutoCompleteDialog;
