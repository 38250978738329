import { ThemeConfig } from 'antd';

// TODO: original theme
// const rootTheme: ThemeConfig = {
//     token: {
//         colorPrimary: "#5e67fe",
//         colorInfo: "#5e67fe",
//         colorTextBase: "#5d5c6d",
//         borderRadius: 8,
//         wireframe: false,
//         sizeStep: 4,
//         sizeUnit: 4,
//     },
//     components: {
//         Card: {
//             borderRadius: 20,
//             borderRadiusLG: 20,
//         },
//         Button: {
//             borderRadius: 10,
//             borderRadiusLG: 10,
//         },
//     },
// };

const twitchTheme: ThemeConfig = {
    token: {
        colorBgBase: '#9147ff46',
        colorPrimary: "#9147ff",
        colorPrimaryBg: '#9147ff46', // box shadow of buttons
        colorErrorBg: '#ff00001a', // box shadow of buttons
        colorInfo: "#ffffff",
        colorTextBase: "#ffffff",
        borderRadius: 8,
        wireframe: false,
        sizeStep: 4,
        sizeUnit: 4,
    },
    components: {
        Card: {
            borderRadius: 20,
            borderRadiusLG: 20,
            colorBgContainer: '#421b79',
            colorBorderSecondary: '#9751ff81'
        },
        Button: {
            borderRadius: 10,
            borderRadiusLG: 10
        },
        Input: {
            colorBgContainer: 'transparent',
            colorBorder: '#9147ff'
        },
        InputNumber: {
            colorBgContainer: 'transparent',
            colorBorder: '#9147ff'
        }
    },
};

export default twitchTheme;
