import { PropsWithChildren } from 'react';

import { Button, Card, Typography } from 'antd';

import styles from './index.module.scss';

export type SectionAction = {
    text: string;
    icon?: JSX.Element;
    danger?: boolean;
    onClick?: () => void;
};

type Props = {
    title: string;
    loading?: boolean;
    actions?: SectionAction[];
};

function Section({ title, actions, loading, children }: PropsWithChildren<Props>)
{
    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <Typography.Title level={2}>{title}</Typography.Title>
                <div>
                    {
                        actions?.map((action, index) => (
                            <Button
                                key={index}
                                icon={action.icon}
                                type="primary"
                                size="middle"
                                danger={action.danger}
                                onClick={() => action.onClick?.()}
                            >
                                {action.text}
                            </Button>
                        ))
                    }
                </div>
            </div>
            <Card className={styles.content} loading={loading} bordered={false}>
                {children}
            </Card>
        </section>
    );
}

export default Section;
