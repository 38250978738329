import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useApi<T extends (...args: any[]) => Promise<any>>(api: T)
{
    const navigate = useNavigate();

    const fetch = useCallback(async (...args: Parameters<T>): Promise<ReturnType<T>> =>
    {
        try
        {
            return await api(...args);
        }
        catch (err: any)
        {
            if (err.status)
            {
                navigate('/logout?error=Session expired. Please login again.');
            }

            throw err;
        }
    }, []);

    return { fetch };
}

export default useApi;
