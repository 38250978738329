import { useState } from 'react';

import { Card, Typography } from 'antd';
import { TbClick, TbInnerShadowTopRight, TbReplace } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import AutoCompleteDialog, { AutoCompleteDialogItem } from 'components/AutoCompleteDialog';
import Section from 'components/Section';
import GroupForm from 'features/GroupForm';

import useApi from 'hooks/useApi';
import { Group, GroupReward, getGroups, toggleGroup } from 'utils/api/groups';
import { formatNumber } from 'utils/format';

import styles from './index.module.scss';

type Props = {
    defaultGroup?: Group<GroupReward>;
};

function ActiveGroup({ defaultGroup }: Props)
{
    const { fetch: apiGetGroups } = useApi(getGroups);
    const { fetch: apiToggleGroup } = useApi(toggleGroup);

    const navigate = useNavigate();

    const [open, setOpen] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [items, setItems] = useState<AutoCompleteDialogItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [group, setGroup] = useState<Group<GroupReward> | undefined>(defaultGroup);
    const [toggling, setToggling] = useState<boolean>(false);

    function handleOpenAutocomplete()
    {
        setOpen('autocomplete');
        handleQuery('');
    }

    function handleClose()
    {
        setOpen('');
        setItems([]);
    }

    async function handleQuery(text: string)
    {
        setLoading(true);

        const data = await apiGetGroups({ populate: false, limit: 5, page: 0, active: false, query: text });

        setItems(data.map(group => ({
            key: group.id,
            text: group.title,
            subText: `Rewards: ${group.rewards.length}`,
        })));

        setLoading(false);
    }

    async function handleSelect(id: string)
    {
        setToggling(true);

        handleClose();
        const data = await apiToggleGroup(id);
        setGroup(data);

        setToggling(false);
    }

    async function handleCreate(text: string)
    {
        setQuery(text);
        setOpen('form');
    }

    async function handleCreated(id: string)
    {
        await apiToggleGroup(id);
        navigate(`/group/${id}`);
    }

    const enabledActions = [
        {
            text: 'Change',
            icon: <TbReplace />,
            onClick: handleOpenAutocomplete,
        },
    ];

    const disabledActions = [
        {
            text: 'Select',
            icon: <TbClick />,
            onClick: handleOpenAutocomplete,
        },
    ];

    return (
        <>
            <Section
                title="Active group"
                loading={toggling}
                actions={group ? enabledActions : disabledActions}
            >
                {
                    group ? (
                        <div className={styles.container}>
                            <div className={styles.header}>
                                <Typography.Title level={5}>Group</Typography.Title>
                                <Typography.Text>{group.title}</Typography.Text>
                            </div>
                            <div className={styles.content}>
                                <Typography.Title level={5}>Rewards</Typography.Title>
                                <div>
                                    {
                                        group.rewards.map((reward, index) => (
                                            <Card key={index} className={styles.reward}>
                                                <Typography.Text>{reward.title}</Typography.Text>
                                                <div>
                                                    <TbInnerShadowTopRight />
                                                    <Typography.Text>{formatNumber(reward.cost)}</Typography.Text>
                                                </div>
                                            </Card>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h1>No Data Found</h1>
                    )
                }
            </Section>

            <AutoCompleteDialog
                open={open === 'autocomplete'}
                title="Groups"
                items={items}
                loading={loading}
                onClick={handleSelect}
                onChange={handleQuery}
                onCreate={handleCreate}
                onClose={handleClose}
            />

            <GroupForm
                open={open === 'form'}
                initialValues={{ title: query }}
                onCreated={handleCreated}
                onClose={handleClose}
            />
        </>
    );
}

export default ActiveGroup;
