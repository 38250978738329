import { LoaderFunctionArgs } from 'react-router-dom';

import { GroupReward, Group as GroupType, getGroupById, getGroups } from 'utils/api/groups';
import { privateLoader } from 'utils/loader';

import Group from './Group';
import GroupDetail from './GroupDetail';

async function groupLoader(): Promise<GroupType[] | any>
{
    try
    {
        return await getGroups({ populate: false });
    }
    catch (err: any)
    {
        if (err.status)
        {
            return err;
        }

        throw err;
    }
}

async function groupDetailLoader({ params }: LoaderFunctionArgs): Promise<GroupType<GroupReward> | any>
{
    try
    {
        const { id } = params;
        return await getGroupById(id!);
    }
    catch (err: any)
    {
        if (err.status)
        {
            return err;
        }

        throw err;
    }
}

export default {
    path: 'group',
    children: [
        {
            path: '',
            element: <Group />,
            loader: privateLoader(groupLoader),
            errorElement: <div> Group failed to load</div>,
        },
        {
            path: ':id',
            element: <GroupDetail />,
            loader: privateLoader(groupDetailLoader),
            errorElement: <div>Group :id failed to load</div>,
        },
    ],
};
