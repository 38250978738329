import { useEffect, useState } from 'react';

import { Button, Card, Dropdown, Typography } from 'antd';
import { TbClick, TbDots, TbInnerShadowTopRight, TbPencil, TbX } from 'react-icons/tb';
import { useLoaderData } from 'react-router-dom';

import AutoCompleteDialog, { AutoCompleteDialogItem } from 'components/AutoCompleteDialog';
import Section, { SectionAction } from 'components/Section';
import RewardForm from 'features/RewardForm';

import useApi from 'hooks/useApi';
import { Group, GroupReward, addRewardToGroup, updateGroup } from 'utils/api/groups';
import { getRewards } from 'utils/api/rewards';
import { formatNumber } from 'utils/format';

import styles from './index.module.scss';

function GroupDetail()
{
    const { fetch: apiAddRewardToGroup } = useApi(addRewardToGroup);
    const { fetch: apiUpdateGroup } = useApi(updateGroup);
    const { fetch: apiGetRewards } = useApi(getRewards);

    const data = useLoaderData() as Group<GroupReward>;

    const [group, setGroup] = useState<Group<GroupReward>>(data);
    const [adding, setAdding] = useState<boolean>(false);

    const [open, setOpen] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [items, setItems] = useState<AutoCompleteDialogItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [reward, setReward] = useState<GroupReward>();

    useEffect(() =>
    {
        setGroup(data);
    }, [data]);

    function handleOpenAutocomplete()
    {
        setOpen('autocomplete');
        handleQuery('');
    }

    function handleClose()
    {
        setOpen('');
        setItems([]);
        setReward(undefined);
    }

    async function handleQuery(text: string)
    {
        setLoading(true);

        const data = await apiGetRewards({ rewardIds: group.rewards.map(r => r.id), query: text });

        setItems(data.map(reward => ({
            key: reward.id,
            text: reward.title,
            subText: reward.description || 'No description',
            metadata: (
                <>
                    <TbInnerShadowTopRight />
                    <span>{reward.cost}</span>
                </>
            ),
        })));

        setLoading(false);
    }

    async function handleSelect(id: string)
    {
        setAdding(true);

        handleClose();
        const data = await apiAddRewardToGroup(group.id, id);
        setGroup({ ...group, rewards: [...group.rewards, data] });

        setAdding(false);
    }

    function handleEdit(reward: GroupReward)
    {
        return () =>
        {
            setOpen('form');
            setReward(reward);
        };
    }

    function handleRemove(id: string)
    {
        return async () =>
        {
            const confirmed = window.confirm('Are you sure you want to remove this reward from this group?');

            if (!confirmed)
            {
                return;
            }

            const rewards = group.rewards.filter((r) => r.id !== id);

            await apiUpdateGroup(group.id, {
                ...group,
                rewards: rewards.map(r => r.id),
            });

            setGroup({ ...group, rewards });
        };
    }

    function handleCreate(text: string)
    {
        setQuery(text);
        setOpen('form');
    }

    function handleUpdated(id: string, data: GroupReward)
    {
        handleClose();
        setGroup({
            ...group,
            rewards: group.rewards.map((r) => r.id === id ? data : r),
        });
    }

    function handleDeleted(id: string)
    {
        handleClose();
        setGroup({
            ...group,
            rewards: group.rewards.filter((r) => r.id !== id),
        });
    }

    const actions: SectionAction[] = [
        {
            icon: <TbClick />,
            text: 'Add',
            onClick: handleOpenAutocomplete,
        }
    ];

    const dropdownItems = (reward: GroupReward) => [
        {
            key: 'edit',
            label: 'Edit',
            icon: <TbPencil />,
            onClick: handleEdit(reward),
        },
        {
            key: 'remove',
            label: 'Remove',
            icon: <TbX />,
            onClick: handleRemove(reward.id),
        },
    ];

    return (
        <>
            <Card className={styles.header} bordered={false}>
                <div>
                    <Typography.Text>Groups</Typography.Text>
                    <Typography.Title>{group.title}</Typography.Title>
                </div>
                <div>
                    <Typography.Text>Status</Typography.Text>
                    <Typography.Text>{group.enabled ? 'Active' : '-'}</Typography.Text>
                </div>
            </Card>

            <Section
                title='Rewards'
                actions={actions}
                loading={adding}
            >
                <div className={styles.rewards}>
                    {
                        group.rewards.map((reward) => (
                            <Card key={reward.id} className={styles.card}>
                                <Typography.Text>{reward.title}</Typography.Text>
                                <div>
                                    <div>
                                        <TbInnerShadowTopRight />
                                        <Typography.Text>{formatNumber(reward.cost)}</Typography.Text>
                                    </div>
                                    <div>
                                        <Dropdown
                                            menu={{ items: dropdownItems(reward) }}
                                            placement="bottomRight"
                                        >
                                            <Button type="text">
                                                <TbDots />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card>
                        ))
                    }
                </div>
            </Section>

            <AutoCompleteDialog
                open={open === 'autocomplete'}
                title="Rewards"
                items={items}
                loading={loading}
                onClick={handleSelect}
                onChange={handleQuery}
                onCreate={handleCreate}
                onClose={handleClose}
            />

            <RewardForm
                open={open === 'form'}
                initialValues={!reward ? { title: query } : undefined}
                reward={reward}
                onClose={handleClose}
                onCreated={handleSelect}
                onUpdated={handleUpdated}
                onDeleted={handleDeleted}
            />
        </>
    );
}

export default GroupDetail;
