import { Card, Typography } from 'antd';
import Loader from 'components/Loader';

import styles from './index.module.scss';

type Props = {
    text?: string;
};

function Fallback({ text }: Props)
{
    return (
        <div className={styles.container}>
            <Card className={styles.card}>
                <div>
                    <div className="logo" />
                    <div>
                        <Loader />
                        <Typography.Text>{text ?? "Loading"}...</Typography.Text>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Fallback;
