import { createBrowserRouter, LoaderFunctionArgs, redirect, RouterProvider } from 'react-router-dom';

import Fallback from 'components/Fallback';
import ProfileContextProvider from 'contexts/ProfileContext';

import Page from 'features/Page';

import { baseUrl } from 'utils/api';
import { getGroups } from 'utils/api/groups';
import { fetchedProfile, privateLoader, publicLoader, setProfileCache } from 'utils/loader';

import dashboard from 'views/dashboard';
import group from 'views/group';
import Login from 'views/login';

function loginLoader()
{
    return null;
}

async function appLoader()
{
    try
    {
        const groups = await getGroups();
        return groups;
    }
    catch (err)
    {
        throw err;
    }
}

async function logoutLoader({ request }: LoaderFunctionArgs)
{
    try
    {
        const requestUrl = new URL(request.url);
        return redirect(`${baseUrl}/auth/twitch/logout?${requestUrl.searchParams}`);
    }
    catch (err)
    {
        throw err;
    }
}

// This is a workaround for a bug to get the demo running.
// TODO: replace with real implementation when the bug is fixed.
function use(promise: any)
{
    if (promise.status === 'fulfilled')
    {
        return promise.value;
    }
    else if (promise.status === 'rejected')
    {
        throw promise.reason;
    }
    else if (promise.status === 'pending')
    {
        throw promise;
    }
    else
    {
        promise.status = 'pending';
        promise.then(
            (result: any) =>
            {
                promise.status = 'fulfilled';
                promise.value = result;
            },
            (reason: any) =>
            {
                promise.status = 'rejected';
                promise.reason = reason;
            },
        );
        throw promise;
    }
}

function App()
{
    const data = use(fetchedProfile);

    setProfileCache(data);

    const router = createBrowserRouter([
        {
            path: '/login',
            element: <Login />,
            loader: publicLoader(loginLoader),
        },
        {
            path: '/logout',
            loader: logoutLoader,
        },
        {
            path: '/',
            element: <Page />,
            loader: privateLoader(appLoader),
            shouldRevalidate: () => false,
            children: [
                group,
                dashboard,
                {
                    path: '*',
                    element: <h1>Private - 404 Not Found</h1>,
                },
            ],
        },
    ]);

    return (
        <ProfileContextProvider data={data}>
            <RouterProvider
                router={router}
                fallbackElement={<Fallback />}
            />
        </ProfileContextProvider>
    );
}

export default App;
