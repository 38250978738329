import { Dispatch, SetStateAction } from 'react';
import { createContext, useState, PropsWithChildren } from 'react';
import { Profile } from 'utils/api/profile';

type State = Profile | undefined;
type Value = [State, Dispatch<SetStateAction<State>>] | undefined;
type Props = { data: State; };

export const ProfileContext = createContext<Value>(undefined);

function ProfileContextProvider({ data, children }: PropsWithChildren<Props>)
{
    const [profile, setProfile] = useState<State>(data);

    return (
        <ProfileContext.Provider value={[profile, setProfile]}>
            {children}
        </ProfileContext.Provider>
    );
}

export default ProfileContextProvider;
