import { useState } from 'react';

import { Alert, Button, Form, FormProps, Input } from 'antd';

import Dialog from 'components/Dialog';
import useApi from 'hooks/useApi';
import { Group, createGroup, deleteGroup, updateGroup } from 'utils/api/groups';

import styles from './index.module.scss';

type Props = {
    open: boolean;
    group?: Group;
    initialValues?: Pick<Group, 'title'>;
    onClose: () => void;
    onUpdated?: (id: string, data: Group) => void;
    onCreated?: (id: string) => void;
    onDeleted?: (id: string) => void;
};

function GroupForm({ open, group, initialValues, onCreated, onUpdated, onDeleted, onClose }: Props)
{
    const { fetch: apiUpdateGroup } = useApi(updateGroup);
    const { fetch: apiCreateGroup } = useApi(createGroup);
    const { fetch: apiDeleteGroup } = useApi(deleteGroup);

    const [err, setErr] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(event: Parameters<Required<FormProps>['onFinish']>[0])
    {
        setErr('');
        setLoading(true);

        if (group)
        {
            await apiUpdateGroup(group.id, { ...group, ...event });
            onUpdated?.(group.id, { ...group, ...event });
            // TODO: Temporary until we figure this out properly
            const customEvent = new CustomEvent(`group-updated`, { detail: { ...group, ...event } });
            document.dispatchEvent(customEvent);
        }
        else
        {
            try
            {
                const group = await apiCreateGroup(event);
                onCreated?.(group.id);
                // TODO: Temporary until we figure this out properly
                const customEvent = new CustomEvent(`group-created`, { detail: { ...group, ...event } });
                document.dispatchEvent(customEvent);
            }
            catch (err: any)
            {
                setErr(err.message);
            }
        }

        setLoading(false);
    }

    async function handleDelete()
    {
        const confirmed = window.confirm('Are you sure you want to delete this group?');

        if (!confirmed)
        {
            return;
        }

        setLoading(true);

        await apiDeleteGroup(group!.id);
        onDeleted?.(group!.id);
        // TODO: Temporary until we figure this out properly
        const customEvent = new CustomEvent(`group-deleted`, { detail: group });
        document.dispatchEvent(customEvent);

        setLoading(false);
    }

    function handleClose()
    {
        setErr('');
        setLoading(false);
        onClose();
    }

    return (
        <Dialog
            className={styles.content}
            open={open}
            title={`${group ? 'Edit' : 'Create'} group`}
            onClose={handleClose}
        >
            {err && <Alert message={err} type="error" showIcon style={{ marginBottom: '1rem' }} />}

            <Form
                layout="vertical"
                autoComplete="off"
                disabled={loading}
                initialValues={initialValues || group}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true }, { type: 'string', min: 3 }]}
                >
                    <Input
                        autoFocus
                        type="text"
                        placeholder="Group title"
                    />
                </Form.Item>

                <fieldset className={styles.actions}>

                    <div>
                        <Button htmlType="button" onClick={handleClose} type="primary">
                            Cancel
                        </Button>
                        <Button htmlType="submit" type="primary">
                            {group ? 'Save' : 'Create'}
                        </Button>
                    </div>

                    <div>
                        {
                            group && (
                                <Button
                                    danger
                                    type="primary"
                                    htmlType="button"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            )
                        }
                    </div>
                </fieldset>
            </Form>
        </Dialog>
    );
}

export default GroupForm;
