import { Pagination, Query, apiRequest } from '.';
import { Reward } from './rewards';

export type GroupReward = Reward;

export type Group<T extends GroupReward | string = string> = {
    id: string;
    title: string;
    enabled: boolean;
    rewards: T[];
};

type GetGroupOptions<T extends boolean> = {
    populate: T;
    active?: boolean;
} & Query & Pagination;

export function getGroups(options?: GetGroupOptions<true>): Promise<Group<GroupReward>[]>;
export function getGroups(options?: GetGroupOptions<false>): Promise<Group<string>[]>;
export async function getGroups(options?: GetGroupOptions<true | false>): Promise<Group<GroupReward | string>[]>
{
    return apiRequest('/group', 'GET', { params: options });
}

export function getGroupById(id: string): Promise<Group<GroupReward>>
{
    return apiRequest(`/group/${id}`, 'GET');
}

type CreateGroupOptions = Pick<Group, 'title'>;

export async function createGroup(data: CreateGroupOptions): Promise<Group>
{
    const body = {
        enabled: false,
        rewardIds: [],
        ...data,
    };

    return apiRequest('/group', 'POST', { body });
}

type UpdateGroupOptions = Pick<Group, 'title' | 'enabled' | 'rewards'>;

export async function updateGroup(id: string, data: UpdateGroupOptions): Promise<void>
{
    const { rewards: rewardIds, ...rest } = data;

    const body = {
        ...rest,
        rewardIds,
    };

    return apiRequest(`/group/${id}`, 'PUT', { body });
}

export async function deleteGroup(id: string): Promise<void>
{
    return apiRequest(`/group/${id}`, 'DELETE');
}

export async function toggleGroup(id: string): Promise<Group<GroupReward>>
{
    return apiRequest(`/group/${id}/toggle`, 'POST');
}

export async function addRewardToGroup(id: string, rewardId: string): Promise<Reward>
{
    return apiRequest(`/group/${id}/reward/${rewardId}`, 'POST');
}

export async function removeRewardFromGroup(id: string, rewardId: string): Promise<void>
{
    return apiRequest(`/group/${id}/reward/${rewardId}`, 'DELETE');
}
