import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router-dom';
import { Profile, getProfile } from './api/profile';

let profileCache: Profile | null | undefined = undefined;

export function setProfileCache(profile: Profile | null | undefined)
{
    profileCache = profile;
}

const fetchProfile = async () =>
{
    let profile: Profile | undefined = undefined;

    try
    {
        profile = await getProfile();
    }
    finally
    {
        return profile;
    }
};

export const fetchedProfile = fetchProfile();

export function publicLoader(callback: LoaderFunction)
{
    return async (args: LoaderFunctionArgs): Promise<Response | ReturnType<LoaderFunction>> =>
    {
        if (profileCache)
        {
            return redirect('/');
        }

        return callback(args);
    };
}

export function privateLoader(callback: LoaderFunction)
{
    return async (args: LoaderFunctionArgs) =>
    {
        if (!profileCache)
        {
            return redirect('/login');
        }

        return callback(args);
    };
}