import { useLoaderData } from 'react-router-dom';

import ActiveGroup from 'features/ActiveGroup';
import Analytics from 'features/Analytics';

import { AnalyticsData } from 'utils/api/analytics';
import { Group, GroupReward } from 'utils/api/groups';

type LoaderData = {
    analytics: AnalyticsData;
    activeGroup: Group<GroupReward>;
};

function Dashboard()
{
    const data = useLoaderData() as LoaderData;

    return (
        <>
            <Analytics
                reward={data.analytics.mostPopularRewardThisWeek}
                group={data.analytics.mostPopularGroupThisWeek}
                leaderboard={data.analytics.userLeaderboardThisWeek}
            />

            <ActiveGroup defaultGroup={data.activeGroup} />
        </>
    );
}

export default Dashboard;
