import { apiRequest } from '.';

export type Leaderboard = {
    name: string;
    totalCost: number;
};

export type Statistic = {
    title: string;
    totalCost: number;
    totalRedeems: number;
};

export type AnalyticsData = {
    mostPopularRewardThisWeek: Statistic | null;
    mostPopularGroupThisWeek: Statistic | null;
    userLeaderboardThisWeek: Leaderboard[];
};

export function getAnalytics(): Promise<AnalyticsData>
{
    return apiRequest("/analytics", "GET");
}
