import { Pagination, Query, apiRequest } from '.';

export type Reward = {
    id: string;
    title: string;
    enabled: boolean;
    description?: string;
    cost: number;
};

type GetGroupOptions = {
    rewardIds?: string[];
} & Pagination & Query;

export async function getRewards(options?: GetGroupOptions): Promise<Reward[]>
{
    return apiRequest('/reward', 'GET', { params: options });
};

type CreateRewardOptions = Pick<Reward, 'title' | 'description' | 'cost'>;

export async function createReward(data: CreateRewardOptions): Promise<Reward>
{
    const body = {
        enabled: false,
        ...data,
    };

    return apiRequest('/reward', 'POST', { body });
}

type UpdateRewardOptions = Pick<Reward, 'title' | 'description' | 'cost'>;

export async function updateReward(id: string, data: UpdateRewardOptions): Promise<void>
{
    return apiRequest(`/reward/${id}`, 'PUT', { body: data });
}

export async function deleteReward(id: string): Promise<void>
{
    return apiRequest(`/reward/${id}`, 'DELETE');
}
