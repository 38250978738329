import { useState } from 'react';

import { Button, Form, FormProps, Input, InputNumber } from 'antd';

import Dialog from 'components/Dialog';
import useApi from 'hooks/useApi';
import { Reward, createReward, deleteReward, updateReward } from 'utils/api/rewards';

import styles from './index.module.scss';

type Props = {
    open: boolean;
    reward?: Reward;
    initialValues?: Partial<Pick<Reward, 'title' | 'cost' | 'description'>>;
    onClose: () => void;
    onUpdated?: (id: string, data: Reward) => void;
    onCreated?: (id: string) => void;
    onDeleted?: (id: string) => void;
};

function RewardForm({ open, reward, initialValues, onCreated, onUpdated, onDeleted, onClose }: Props)
{
    const { fetch: apiUpdateReward } = useApi(updateReward);
    const { fetch: apiCreateReward } = useApi(createReward);
    const { fetch: apiDeleteReward } = useApi(deleteReward);

    const [loading, setLoading] = useState<boolean>(false);

    async function handleSubmit(event: Parameters<Required<FormProps>['onFinish']>[0])
    {
        setLoading(true);

        if (reward)
        {
            await apiUpdateReward(reward.id, { ...reward, ...event });
            onUpdated?.(reward.id, { ...reward, ...event });
        }
        else
        {
            const reward = await apiCreateReward(event);
            onCreated?.(reward.id);
        }

        setLoading(false);
    }

    async function handleDelete()
    {
        const confirmed = window.confirm('Are you sure you want to delete this reward? This will also delete it from all groups.');

        if (!confirmed)
        {
            return;
        }

        setLoading(true);

        await apiDeleteReward(reward!.id);
        onDeleted?.(reward!.id);

        setLoading(false);
    }

    return (
        <Dialog
            className={styles.content}
            open={open}
            title={`${reward ? 'Edit' : 'Create'} reward`}
            onClose={onClose}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                disabled={loading}
                initialValues={initialValues || reward}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true }, { type: 'string', min: 3 }]}
                >
                    <Input
                        autoFocus
                        type="text"
                        placeholder="Hydrate!"
                    />
                </Form.Item>

                <Form.Item
                    className={styles.cost}
                    name="cost"
                    label="Cost"
                    rules={[{ required: true }, { type: 'integer', min: 1 }]}
                >
                    <InputNumber placeholder="100" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: false }]}
                >
                    <Input.TextArea
                        placeholder="Drink a glass of water!"
                    />
                </Form.Item>
                <fieldset className={styles.actions}>
                    <div>
                        <Button htmlType='button' onClick={onClose} type="primary">
                            Cancel
                        </Button>
                        <Button htmlType="submit" type="primary">
                            {reward ? 'Save' : 'Create'}
                        </Button>
                    </div>

                    <div>
                        {
                            reward && (
                                <Button
                                    danger
                                    type="primary"
                                    htmlType="button"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            )
                        }
                    </div>
                </fieldset>
            </Form>
        </Dialog>
    );
}

export default RewardForm;
