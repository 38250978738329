import { Card, Typography } from 'antd';

import { Leaderboard, Statistic } from 'utils/api/analytics';
import { formatNumber } from 'utils/format';

import styles from './index.module.scss';

type Props = {
    group: Statistic | null;
    reward: Statistic | null;
    leaderboard: Leaderboard[];
};

function LeaderboardItem({ name, totalCost }: Leaderboard)
{
    return (
        <li>
            <Typography.Paragraph>{name}</Typography.Paragraph>
            <Typography.Paragraph>{formatNumber(totalCost)}</Typography.Paragraph>
        </li>
    );
}

function StatisticItem({ title, totalCost, totalRedeems }: Statistic)
{
    return (
        <div>
            <Typography.Title level={3}>{title}</Typography.Title>
            <dl>
                <dt>
                    <Typography.Paragraph>Total redeems</Typography.Paragraph>
                </dt>
                <dd>
                    <Typography.Paragraph>{formatNumber(totalRedeems)}</Typography.Paragraph>
                </dd>

                <dt>
                    <Typography.Paragraph>Avg points spent</Typography.Paragraph>
                </dt>
                <dd>
                    <Typography.Paragraph>{formatNumber(Math.round(totalCost / totalRedeems || 0))}</Typography.Paragraph>
                </dd>

                <dt>
                    <Typography.Paragraph>Total points spent</Typography.Paragraph>
                </dt>
                <dd>
                    <Typography.Paragraph>{formatNumber(totalCost)}</Typography.Paragraph>
                </dd>
            </dl>
        </div>
    );
}

function Analytics({ group, reward, leaderboard }: Props)
{
    return (
        <section className={styles.container}>
            <Card className={styles.stat} bordered={false}>
                <Typography.Title>Most popular reward this week</Typography.Title>
                {
                    <StatisticItem
                        title={reward?.title ?? '-'}
                        totalCost={reward?.totalCost ?? 0}
                        totalRedeems={reward?.totalRedeems ?? 0}
                    />
                }
            </Card>

            <Card className={styles.stat} bordered={false}>
                <Typography.Title>Most popular group this week</Typography.Title>
                {
                    <StatisticItem
                        title={group?.title ?? '-'}
                        totalCost={group?.totalCost ?? 0}
                        totalRedeems={group?.totalRedeems ?? 0}
                    />
                }
            </Card>

            <Card className={styles.leaderboard} bordered={false}>
                <Typography.Title>User leaderboard this week</Typography.Title>
                <ol>
                    {
                        Array(4).fill(null).map((_, index) => <LeaderboardItem key={index} {...(leaderboard[index] ?? { name: '-', totalCost: 0 })} />)
                    }
                </ol>
            </Card>
        </section>
    );
}

export default Analytics;
