import { useEffect, useState } from 'react';

import { Typography } from 'antd';
import { TbCategory, TbHome, TbPlus } from 'react-icons/tb';
import { Link, useLoaderData, useLocation, useNavigate } from 'react-router-dom';

import GroupForm from 'features/GroupForm';

import { Group } from 'utils/api/groups';
import { packageName } from 'utils/name';

import styles from './style.module.scss';

function SideNav()
{
    const data = useLoaderData() as Group[];
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [groups, setGroups] = useState<Group[]>(data ?? []);

    useEffect(() =>
    {
        const handleGroup = (event: CustomEvent) =>
        {
            switch (event.type)
            {
                case 'group-created':
                    setGroups((groups) => [...groups, event.detail]);
                    break;
                case 'group-updated':
                    setGroups((groups) => groups.map((group) => group.id === event.detail.id ? event.detail : group));
                    break;
                case 'group-deleted':
                    setGroups((groups) => groups.filter((group) => group.id !== event.detail.id));
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('group-created', handleGroup as EventListener);
        document.addEventListener('group-updated', handleGroup as EventListener);
        document.addEventListener('group-deleted', handleGroup as EventListener);

        return () =>
        {
            document.removeEventListener('group-created', handleGroup as EventListener);
            document.removeEventListener('group-updated', handleGroup as EventListener);
            document.removeEventListener('group-deleted', handleGroup as EventListener);
        };
    }, []);

    function handleCreated(id: string)
    {
        setOpen(false);
        navigate(`/group/${id}`);
    }

    function locationActive(path: string, children?: any[] | null, parent?: boolean)
    {
        const key = parent ? styles.active : styles.subActive;
        const isActive = path === location.pathname || location.pathname.startsWith(`${path}/`);
        const isChildActive = children?.some((child) => `${path}/${child.id}` === location.pathname);

        return isActive || isChildActive ? key : '';
    }

    return (
        <aside className={styles.container}>
            <div>
                <div />
                <Typography.Title level={3}>{packageName}</Typography.Title>
            </div>
            <ul>
                <li>
                    <Link className={locationActive('/', [], true)} to={'/'}>
                        <TbHome /> <Typography.Text>Dashboard</Typography.Text>
                    </Link>
                </li>
                <li>
                    <Link className={locationActive('/group', groups, true)} to={'/group'}>
                        <TbCategory /> <Typography.Text>Groups</Typography.Text>
                    </Link>
                    {
                        locationActive('/group', groups, true) && (
                            <ul>
                                {
                                    groups?.map((group) => (
                                        <li key={group.id}>
                                            <Link className={locationActive(`/group/${group.id}`)} to={`/group/${group.id}`}>
                                                <Typography.Text>{group.title}</Typography.Text>
                                            </Link>
                                        </li>
                                    ))
                                }
                                <li>
                                    <button onClick={() => setOpen(true)}>
                                        <TbPlus />
                                        <Typography.Text>Create Group</Typography.Text>
                                    </button>
                                </li>
                            </ul>
                        )
                    }
                </li>
            </ul>

            <GroupForm
                open={open}
                onCreated={handleCreated}
                onClose={() => setOpen(false)}
            />
        </aside>
    );
}

export default SideNav;
