import { useEffect, useState } from 'react';

import { Alert, Button, Card, Typography } from 'antd';
import { TbBrandTwitch } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';

import { baseUrl } from 'utils/api';

import styles from './index.module.scss';

function Login()
{
    const location = useLocation();
    const [err, setErr] = useState<string>('');
    const [info, setInfo] = useState<string>('');

    useEffect(() =>
    {
        const params = new URLSearchParams(location.search);
        const error = params.get("error");
        const info = params.get("info");

        if (error)
        {
            setErr(error);
        }

        if (info)
        {
            setInfo(info);
        }
    }, [location.search]);

    function generateLoginLink(provider: string)
    {
        return () =>
        {
            window.location.href = `${baseUrl}/auth/${provider}`;
        };
    }

    return (
        <div className={styles.container}>
            <div>
                {err && <Alert message={err} type="error" showIcon style={{ marginBottom: '1rem', borderColor: 'lightcoral' }} />}
                {info && <Alert message={info} type="warning" showIcon style={{ marginBottom: '1rem', background: '#71a2b2', borderColor: 'lightblue' }} />}

                <Card className={styles.login}>
                    <div>
                        <div className="logo" />
                        <Typography.Title level={2}>Welcome back!</Typography.Title>
                        <Typography.Text>Start managing your rewards faster and better</Typography.Text>
                    </div>
                    <div>
                        <Button className={styles.twitchButton} type='primary' size="large" onClick={generateLoginLink("twitch")}>
                            <TbBrandTwitch />
                            <Typography.Text>Sign in with Twitch</Typography.Text>
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Login;
