import { useEffect, useState } from 'react';

import { Dropdown, Progress } from 'antd';
import { Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { TbChevronDown, TbLogout } from 'react-icons/tb';

import SideNav from 'features/SideNav';
import useProfile from 'hooks/useProfile';

import styles from './index.module.scss';

function Page()
{
    const [profile] = useProfile();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const [percentage, setPercentage] = useState<number>(0);
    const [showProgress, setShowProgress] = useState<boolean>(false);

    useEffect(() =>
    {
        let timer: NodeJS.Timeout | undefined;

        if (navigation.state !== 'idle')
        {
            setShowProgress(true);

            const loadingTime = 5 * 1000; // 5 seconds
            const startTime = Date.now();
            const idleThreshold = 0.99;
            const animationDelay = 16.67; // 60 FPS

            const updateProgress = () =>
            {
                const now = Date.now();
                const elapsed = Math.min(now - startTime, loadingTime);
                const progress = (elapsed / loadingTime);
                setPercentage(progress * (idleThreshold * 100));

                if (progress < idleThreshold)
                {
                    timer = setTimeout(updateProgress, animationDelay);
                }
            };

            timer = setTimeout(updateProgress, animationDelay);
        } else
        {
            setPercentage(100);

            timer = setTimeout(() =>
            {
                setPercentage(0);
                setShowProgress(false);
            }, 400);
        }

        return () => clearTimeout(timer);
    }, [navigation.state]);

    return (
        <>
            {
                showProgress && (
                    <Progress
                        className={styles.progress}
                        percent={percentage}
                        strokeLinecap='square'
                        status="active"
                        size="small"
                        showInfo={false}
                    />
                )
            }

            <div className={styles.container}>
                <SideNav />

                <div>
                    <header>
                        <Dropdown
                            openClassName={styles.dropdownOpen}
                            placement='bottomRight'
                            menu={{
                                items: [
                                    {
                                        key: 'logout',
                                        title: 'Logout',
                                        label: 'Logout',
                                        icon: <TbLogout />,
                                        onClick: () => navigate('/logout?info=Succesfully logged out.'),
                                    }
                                ]
                            }}
                        >
                            <div>
                                <div className={styles.profile} style={{ backgroundImage: `url(${profile!.picture})` }} />
                                <TbChevronDown />
                            </div>
                        </Dropdown>
                    </header>

                    <main>
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    );
}

export default Page;
