import { useEffect, useRef, MouseEvent, DialogHTMLAttributes } from 'react';
import { createPortal } from 'react-dom';

import { Button, Typography } from 'antd';
import { TbX } from 'react-icons/tb';

import styles from './index.module.scss';
import classNames from 'classnames';

type Props = {
    title: string;
    open: boolean;
    onClose?: () => void;
};

function Dialog({ title, open, children, className, onClose, ...props }: DialogHTMLAttributes<{}> & Props)
{
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() =>
    {
        const dialog = ref.current;

        if (open)
        {
            dialog?.showModal();
        } else
        {
            dialog?.close();
        }

        return () =>
        {
            dialog?.close();
        };
    }, [open]);

    function handleDialogClick(event: MouseEvent)
    {
        const rect = ref.current!.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom)
        {
            onClose?.();
        }
    }

    if (!open)
    {
        return null;
    }

    const component = (
        <dialog
            {...props}
            ref={ref}
            className={styles.container}
            onMouseDown={handleDialogClick}
        >
            <div className={styles.header}>
                <Typography.Title>{title}</Typography.Title>
                <Button type="text" htmlType="button" onClick={onClose}>
                    <TbX />
                </Button>
            </div>
            <div className={classNames(styles.content, className)}>
                {children}
            </div>
        </dialog>
    );

    return createPortal(component, document.body);
}

export default Dialog;
