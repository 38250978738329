import classNames from 'classnames';
import { IconBaseProps } from 'react-icons';
import { TbLoader } from 'react-icons/tb';

import styles from './index.module.scss';

function Loader({ className, ...props }: IconBaseProps)
{
    return (
        <TbLoader
            {...props}
            className={classNames(styles.container, className)}
        />
    );
}

export default Loader;
