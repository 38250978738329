import { useState } from 'react';

import { Button, Card, Dropdown, Typography } from 'antd';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { TbDots, TbPencil, TbPlus } from 'react-icons/tb';

import Section from 'components/Section';
import GroupForm from 'features/GroupForm';

import { Group as GroupType } from 'utils/api/groups';

import styles from './index.module.scss';

function Group()
{
    const navigate = useNavigate();
    const data = useLoaderData() as GroupType[];

    const [open, setOpen] = useState<boolean>(false);
    const [group, setGroup] = useState<GroupType>();
    const [groups, setGroups] = useState<GroupType[]>(data);

    function handleEdit(group: GroupType)
    {
        setOpen(true);
        setGroup(group);
    }

    function handleUpdated(id: string, data: GroupType)
    {
        handleClose();
        setGroups(groups.map((g) => g.id === id ? data : g));
    }

    function handleCreated(id: string)
    {
        handleClose();
        navigate(`/group/${id}`);
    }

    function handleDeleted(id: string)
    {
        handleClose();
        setGroups(groups.filter((g) => g.id !== id));
    }

    function handleClose()
    {
        setOpen(false);
        setGroup(undefined);
    }

    const actions = [
        {
            icon: <TbPlus />,
            text: 'Create',
            onClick: () => setOpen(true),
        },
    ];

    const items = (group: GroupType) => [
        {
            key: 'edit',
            label: 'Edit',
            icon: <TbPencil />,
            onClick: () => handleEdit(group),
        }
    ];

    return (
        <Section
            title='Groups'
            actions={actions}
        >
            <div className={styles.container}>
                {
                    groups.map((group) => (

                        <Card key={group.id} className={styles.card}>
                            <Link to={`${group.id}`}>
                                <Typography.Text>{group.title}</Typography.Text>
                            </Link>
                            <Dropdown
                                menu={{ items: items(group) }}
                                placement="bottomRight"
                            >
                                <Button type="text">
                                    <TbDots />
                                </Button>
                            </Dropdown>
                        </Card>
                    ))
                }
            </div>

            <GroupForm
                open={open}
                group={group}
                onCreated={handleCreated}
                onUpdated={handleUpdated}
                onDeleted={handleDeleted}
                onClose={handleClose}
            />
        </Section>
    );
}

export default Group;
